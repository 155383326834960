
export const environment = {
	PUBLIC_API_URL: "https://6p1xe2sgva.execute-api.us-east-1.amazonaws.com/",
	gateway: {
		auction_search: {
			baseUrl: 'api/ad/v1/',
		},
		sign_up: {
			baseUrl: "api/clientes"
		}
	},
	production: true,
};
